import { AclService } from './acl.service';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatExpansionModule,
  MatFormFieldModule, MatIconModule, MatIconRegistry, MatInputModule, MatListModule,
  MatMenuModule,
  MatPaginatorModule, MatProgressSpinnerModule, MatRadioModule,
  MatSelectModule, MatSnackBarModule, MatTableModule, MatToolbarModule, MatTooltipModule
} from '@angular/material';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { AppStoreModule } from '../store/scm-store.module';
import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';
import { HasRoleDirective } from './has-role.directive';
import { LoginGuard } from './login.guard';
import { TokenInterceptor } from './token.service';
import { UpperCaseTextDirective } from './uppercase.directive';
import { UrlInterceptor } from './url-interceptor.service';
import { CurrencyRate } from './currency-rate.pipe';

registerLocaleData(localeIt, 'it');

const IMPORTED: any[] = [
  CommonModule,
  BrowserModule,
  BrowserAnimationsModule,
  MatButtonModule,
  MatToolbarModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatTableModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatListModule,
  MatTooltipModule,
  MatDialogModule,
  MatSnackBarModule,
  HttpClientModule,
  ReactiveFormsModule,
  FormsModule,
  AppStoreModule,
  FlexLayoutModule,
  MatRadioModule,
  MatDatepickerModule,
  MatMenuModule,
  MatMomentDateModule,
  MatProgressSpinnerModule,
  MatPaginatorModule
];

@NgModule({
  imports: IMPORTED,
  providers: [
    AuthService,
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    AuthGuard,
    LoginGuard,
    CookieService,
    AclService
  ],
  declarations: [UpperCaseTextDirective, HasRoleDirective, CurrencyRate],
  // exports: IMPORTED.concat([UpperCaseTextDirective, HasRoleDirective])
  exports: [...IMPORTED, UpperCaseTextDirective, HasRoleDirective, CurrencyRate]
})
export class SharedModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}
