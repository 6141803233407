import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'currencyRate'
})
export class CurrencyRate implements PipeTransform {
  transform(value: number, rate: number = 0) {
    return value * rate;
  }

}