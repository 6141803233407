// tslint:disable: max-line-length
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { orderBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { CorseLuceService } from '../_services/corse-luce.service';
import { DatasheetService } from '../_services/datasheet.service';
import { DocumentationLanguageService } from '../_services/documentation-language.service';
import { InstallationService } from '../_services/installation.service';
import { ListinoService } from '../_services/listino.service';
import { StandardElectricService } from '../_services/standard-electric.service';
import { TranslationService } from '../_services/translation.service';
import { VpRulesService } from '../_services/vp-rules.service';
import { VpService } from '../_services/vp.service';

@Component({
  selector: 'app-anagrafiche',
  templateUrl: './anagrafiche.component.html',
  styleUrls: ['./anagrafiche.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnagraficheComponent implements OnInit {

  constructor(
    protected fb: FormBuilder,
    protected http: HttpClient,
    protected corseLuceSrv: CorseLuceService,
    protected standardElectricSrv: StandardElectricService,
    protected listiniSrv: ListinoService,
    protected vpsSrv: VpService,
    protected translationsSrv: TranslationService,
    protected documentationLanguageSrv: DocumentationLanguageService,
    protected installationSrv: InstallationService,
    protected datasheetSrv: DatasheetService,
    protected vpRuleSrv: VpRulesService
  ) { }

  priceListForm = this.fb.group({
    name: ['', Validators.required],
    kind: ['', Validators.required],
    importFile: [undefined, Validators.required]
  }); // c'è un custom validator più in giù

  vpCtrl = new FormControl(null, Validators.required);
  translationsCtrl = new FormControl(null, Validators.required);
  installationSpecsCtrl = new FormControl(null, Validators.required);
  metricDatasheetCtrl = new FormControl(null, Validators.required);
  imperialDatasheetCtrl = new FormControl(null, Validators.required);
  vpRulesCtrl = new FormControl(null, Validators.required);

  corsaLuceForm = this.fb.group({
    code: ['', Validators.required]
  });

  standardElectricForm = this.fb.group({
    code: ['', Validators.required]
  });

  docLanguageForm = this.fb.group({
    code: ['', Validators.required]
  });

  pricesManItForm = this.fb.group({
    techDayUp: ['', [Validators.required, Validators.min(0)]],
    travelDayUp: ['', [Validators.required, Validators.min(0)]],
    workHoursWeUp: ['', [Validators.required, Validators.min(0)]]
  });

  pricesManFkForm = this.fb.group({
    techDayUp: ['', [Validators.required, Validators.min(0)]],
    travelDayUp: ['', [Validators.required, Validators.min(0)]],
    workHoursWeUp: ['', [Validators.required, Validators.min(0)]]
  });

  parametersForm = this.fb.group({
    travelCoefficent: ['', Validators.required]
  });

  corseLuce$ = new BehaviorSubject<string[]>([]);
  standardElectrics$ = new BehaviorSubject<string[]>([]);
  docLanguages$ = new BehaviorSubject<string[]>([]);

  listiniColumns: string[] = ['nome', 'tipo', 'updatedAt', 'actions', 'info'];
  listini$ = new BehaviorSubject<any[]>([]);
  vp$ = this.vpsSrv.list()
    .pipe(map(vps => vps[0]));

  translation$ = this.translationsSrv.list()
    .pipe(map(translations => translations[0]));

  installation$ = this.installationSrv.list()
    .pipe(map(installations => installations[0]));

  metricDatasheet$ = this.datasheetSrv.list('metric')
    .pipe(map(installations => installations[0]));
  imperialDatasheet$ = this.datasheetSrv.list('imperial')
    .pipe(map(installations => installations[0]));

  showVPFeedback = false;
  showTranslationFeedback = false;
  showInstallationSpecsFeedback = false;
  showDatasheetFeedback_metric = false;
  showDatasheetFeedback_imperial = false;
  showVpRulesFeedback = false;
  showPricesUpdatedFeedbackIt = false;
  showPricesUpdatedFeedbackFk = false;

  isUploading = false;

  priceListDefinitions =
    'CODIFICA OL:\nVM Name (required) \nVM000: vm price (required)\nEach VP is one column, must start with "VP"\nEach VP value can be: \n  - number (vp price), \n  - NA (not compatible), \n  - TBD (to be defined), \n  - (cost 0)';
  vpDefinitions =
    'CODE:\nVP code (required)\nFAMILY: es. Reflex, Throat (required)\nNOTE: optional note\none more column for each language, contains the description translated in different languages';
  translationsDefinitions =
    'CODE\nOriginal code to translate (required)\nOne more column foreach language, contains the code translated in different languages';

  priceListFormValidator(): ValidatorFn {
    return (control: AbstractControl): any | null => {
      const currentListini = this.listini$.value;
      const exists = currentListini.find(lst => lst.name === control.value.name && lst.kind === control.value.kind);
      if (exists) {
        this.priceListForm.get('name').setErrors({ nameExists: true });
        return { nameExists: true };
      } else {
        this.priceListForm.get('name').setErrors(null);
        return null;
      }
    };
  }

  ngOnInit() {

    this.corseLuceSrv.list()
      .subscribe(values => {
        this.corseLuce$.next(values);
      });

    this.standardElectricSrv.list()
      .subscribe(values => {
        this.standardElectrics$.next(values);
      });

    this.documentationLanguageSrv.list()
      .subscribe(values => {
        this.docLanguages$.next(values);
      });

    this.listiniSrv.list()
      .pipe(
        map(values => orderBy(values, ['kind', 'name'])),
      )
      .subscribe(values => {
        this.listini$.next(values);
      });

    this.installationSrv.getManPrices_it().subscribe(prices => {
      this.pricesManItForm.patchValue(prices[0]);
    });

    this.installationSrv.getManPrices_fc().subscribe(prices => {
      this.pricesManFkForm.patchValue(prices[0]);
    });
    this.priceListForm.setValidators(this.priceListFormValidator());

    this.installationSrv.getParameters()
      .subscribe(parameters => this.parametersForm.patchValue(parameters[0]));
  }

  priceListSubmit() {
    if (this.priceListForm.invalid) {
      return;
    }

    this.isUploading = true;

    let currentListini = this.listini$.value;
    const { name, kind, importFile } = this.priceListForm.value;

    const exists = currentListini.find(lst => lst.name === name && lst.kind === kind);
    if (!exists) { // listino già esistente
      let defaultListino: boolean;
      let atLeastOneListinoDefault: any;
      atLeastOneListinoDefault = currentListini.find(lst => lst.default === true);

      // + di un listino
      if (currentListini.length > 0) {
        // + di un listino ma nessuno di default
        if (atLeastOneListinoDefault === undefined) {
          defaultListino = true;
        } else {
          // + di un listino ma almeno uno di default
          defaultListino = false;
        }
      }

      // primo listino
      if (currentListini.length === 0) {
        defaultListino = true;
      }

      this.listiniSrv.import(name, kind, importFile.files[0], defaultListino)
        .subscribe(val => {
          currentListini.push(val);
          currentListini = orderBy(currentListini, ['kind', 'name']);
          this.listini$.next(currentListini);
          this.priceListForm.reset();
          this.isUploading = false;
        });
    }
  }

  submitVP() {
    if (this.vpCtrl.invalid) {
      return;
    }

    const formData = new FormData();
    this.isUploading = true;
    formData.append('import', this.vpCtrl.value.files[0]);
    this.vpsSrv.import(formData)
      .subscribe(_ => {
        this.vpCtrl.reset();
        this.showVPFeedback = true;
        this.isUploading = false;
      });
  }

  submitTranslations() {
    if (this.translationsCtrl.invalid) {
      return;
    }

    const formData = new FormData();
    this.isUploading = true;
    formData.append('import', this.translationsCtrl.value.files[0]);
    this.translationsSrv.import(formData)
      .subscribe(_ => {
        this.translationsCtrl.reset();
        this.showTranslationFeedback = true;
        this.isUploading = false;
      });
  }

  submitInstallationSpecs() {
    if (this.installationSpecsCtrl.invalid) {
      return;
    }

    const formData = new FormData();
    formData.append('import', this.installationSpecsCtrl.value.files[0]);
    this.installationSrv.import(formData)
      .subscribe(_ => {
        this.installationSpecsCtrl.reset();
        this.showInstallationSpecsFeedback = true;
        this.isUploading = false;
      });
  }

  submitDatasheet(unit: 'metric' | 'imperial' = 'metric') {
    if (this[`${unit}DatasheetCtrl`].invalid) {
      return;
    }

    const formData = new FormData();
    formData.append('import', this[`${unit}DatasheetCtrl`].value.files[0]);
    this.datasheetSrv.import(formData, unit)
      .subscribe(_ => {
        this[`${unit}DatasheetCtrl`].reset();
        this[`showDatasheetFeedback_${unit}`] = true;
        this.isUploading = false;
      });
  }

  submitVpRules() {
    if (this.vpRulesCtrl.invalid) {
      return;
    }

    const formData = new FormData();
    formData.append('import', this.vpRulesCtrl.value.files[0]);
    this.vpRuleSrv.import(formData)
      .subscribe(_ => {
        this.vpRulesCtrl.reset();
        this.showVpRulesFeedback = true;
        this.isUploading = false;
      });
  }

  submitCorsaLuce() {
    if (this.corsaLuceForm.valid) {
      const submitted = this.corsaLuceForm.get('code').value;
      const currentCorseLuce = this.corseLuce$.value;

      const index = currentCorseLuce.indexOf(submitted);
      if (index !== -1) { // corsaLuce già esistente
        return;
      }

      this.corseLuceSrv.add(this.corsaLuceForm.get('code').value)
        .subscribe(corsaLuce => {
          currentCorseLuce.push(corsaLuce);
          this.corseLuce$.next(currentCorseLuce);
          this.corsaLuceForm.reset();
        });
    }
  }

  removeCorsaLuce(clCode: string) {
    this.corseLuceSrv.remove(clCode)
      .subscribe(corsaLuce => {
        const currentCorseLuce = this.corseLuce$.value;
        const index = currentCorseLuce.indexOf(corsaLuce);
        if (index !== -1) {
          currentCorseLuce.splice(index, 1);
          this.corseLuce$.next(currentCorseLuce);
        }
      });
  }

  submitStandardElectric() {
    if (this.standardElectricForm.valid) {
      const submitted = this.standardElectricForm.get('code').value;
      const currentStandardElectrics = this.standardElectrics$.value;

      const index = currentStandardElectrics.indexOf(submitted);
      if (index !== -1) { // standardElectrics già esistente
        return;
      }

      this.standardElectricSrv.add(this.standardElectricForm.get('code').value)
        .subscribe(standardElectric => {
          currentStandardElectrics.push(standardElectric);
          this.standardElectrics$.next(currentStandardElectrics);
          this.corsaLuceForm.reset();
        });
    }
  }

  removeStandardElectric(seCode: string) {
    this.standardElectricSrv.remove(seCode)
      .subscribe(standardElectric => {
        const currentStandardElectrics = this.standardElectrics$.value;
        const index = currentStandardElectrics.indexOf(standardElectric);
        if (index !== -1) {
          currentStandardElectrics.splice(index, 1);
          this.standardElectrics$.next(currentStandardElectrics);
        }
      });
  }

  submitDocLanguage() {
    if (this.docLanguageForm.valid) {
      const submitted = this.docLanguageForm.get('code').value;
      const currentDocLanguages = this.docLanguages$.value;

      const index = currentDocLanguages.indexOf(submitted);
      if (index !== -1) { // docLanguage già esistente
        return;
      }

      this.documentationLanguageSrv.add(this.docLanguageForm.get('code').value)
        .subscribe(docLanguage => {
          currentDocLanguages.push(docLanguage);
          this.docLanguages$.next(currentDocLanguages);
          this.docLanguageForm.reset();
        });
    }
  }

  removeDocLanguage(dlCode: string) {
    this.documentationLanguageSrv.remove(dlCode)
      .subscribe(docLanguage => {
        const currentDocLanguages = this.docLanguages$.value;
        const index = currentDocLanguages.indexOf(docLanguage);
        if (index !== -1) {
          currentDocLanguages.splice(index, 1);
          this.docLanguages$.next(currentDocLanguages);
        }
      });
  }

  removeListino(id: string) {
    this.listiniSrv.remove(id)
      .subscribe(listino => {
        const currentListini = this.listini$.value;
        const exists = currentListini.find(lst => lst._id === id);

        if (exists) {
          const index = currentListini.indexOf(exists);
          currentListini.splice(index, 1);
          this.listini$.next(currentListini);
        }
      });
  }

  updateListino(id: string, defaultListino: boolean, file?: any) {

    const prevDefault = this.listini$.value.find(lst => lst.default);
    this.listiniSrv.update(id, defaultListino, file)
      .pipe(
        tap(item => item.updated = true),
        tap(item => {
          const values = this.listini$.value;

          if (!file && defaultListino) {
            // tslint:disable-next-line: no-unused-expression
            prevDefault !== undefined ? prevDefault.default = false : null;
          }

          if (file && !defaultListino) {
            if (prevDefault.name === item.name && prevDefault.default) {
              item.default = true;
            }
          }

          const oldItem = values.find(value => value._id === id);
          const index = values.indexOf(oldItem);
          values.splice(index, 1, item);
          this.listini$.next(values);
        }),
        delay(3000)
      )
      .subscribe(newItem => {
        newItem.updated = false;
        this.listini$.next(this.listini$.value);
      });
  }

  submitPricesManIt() {
    if (this.pricesManItForm.valid) {
      this.installationSrv.addManPrices_it(this.pricesManItForm.value)
        .pipe(
          tap((_) => this.showPricesUpdatedFeedbackIt = true),
          delay(3000)
        )
        .subscribe((newPrices) => {
          this.showPricesUpdatedFeedbackIt = false;
          this.pricesManItForm.patchValue(newPrices);
        });
    }
  }

  submitPricesManFk() {
    if (this.pricesManFkForm.valid) {
      this.installationSrv.addManPrices_fk(this.pricesManFkForm.value)
        .pipe(
          tap((_) => this.showPricesUpdatedFeedbackFk = true),
          delay(3000)
        )
        .subscribe((newPrices) => {
          this.showPricesUpdatedFeedbackFk = false;
          this.pricesManFkForm.patchValue(newPrices);
        });
    }
  }

  submitTravelCoefficent() {
    const travelCoefficent = this.parametersForm.get('travelCoefficent');
    if (travelCoefficent.valid) {
      this.installationSrv.addParameters('travelCoefficent', travelCoefficent.value)
        .subscribe(newParam => travelCoefficent.patchValue(newParam.value));
    }
  }
}
