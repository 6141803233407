import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-revision-dialog',
  templateUrl: './revision-dialog.component.html',
  styleUrls: ['./revision-dialog.component.scss']
})
export class RevisionDialogComponent implements OnInit {
  btnText = 'Save';
  prevRevision: number;

  revisionForm = new FormGroup({
    comment: new FormControl(''),
    revision: new FormControl(false)
  });

  constructor(
    public dialogRef: MatDialogRef<RevisionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public quotationIdData: { quotationData: any, quotationId: string }) {
    this.revisionForm.get('revision').patchValue(quotationIdData.quotationData.revision);
    this.prevRevision = this.quotationIdData.quotationData.revision;
    dialogRef.disableClose = true;

  }

  ngOnInit() {
    console.log(this.quotationIdData.quotationData)
    if (this.quotationIdData.quotationData.currency === 'USD') {
      this.revisionForm.addControl('currencyConversionRate', new FormControl(this.quotationIdData.quotationData.currencyConversionRate, Validators.required))
    }
    this.revisionForm.get('revision').valueChanges
      .subscribe((revValue) => {
        revValue !== this.quotationIdData.quotationData.revision
          ? this.btnText = 'Create new revision'
          : this.btnText = 'Save';
      });
  }

  save() {
    const quotComment = this.revisionForm.value.comment;
    const revision = this.revisionForm.value.revision;
    const quotationId = this.quotationIdData.quotationId;
    const quotationData = this.quotationIdData.quotationData;
    quotationData.comments = quotationData.comments || [];
    if (quotComment) {
      quotationData.comments.push(quotComment);
    }
    const data: any = {
      quotationId,
      quotationData,
      revision
    }
    if (!!this.revisionForm.get('currencyConversionRate')) {
      data.currencyConversionRate = this.revisionForm.value.currencyConversionRate;
      quotationData.currencyConversionRate = data.currencyConversionRate;
    }
    quotationData.revision = revision !== quotationData.revision ? revision : quotationData.revision;
    this.dialogRef.close(data);
  }

  closeWithoutSave() {
    this.dialogRef.close(null);
  }
}
