import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DatasheetService {

  constructor(protected http: HttpClient) { }

  import(formData, unit: string = 'metric'): Observable<any> {
    return this.http.post(`/datasheet/${unit}/import`, formData);
  }

  list(unit: string = 'metric'): Observable<any> {
    return this.http.get<any>(`/datasheet/${unit}`);
  }
}
